$(document).on('click', '.pagination-btn', function(e) {
	e.preventDefault();

	var pagination = $(".pagination");
	var offset = pagination.data('offset') ? pagination.data('offset') : 0;
	var pages = pagination.data('pages') ? pagination.data('pages') : 2;
	var current = pagination.data('current') ? pagination.data('current') : 1;
	
	var perPage = 8;
	var total = pages * perPage;

	if ($(this).hasClass('js-gallery-prev')) {

		if(current == 1) {
			current = pages;
			offset = (pages * perPage) - perPage;
		} else {
			console.log('test');
			current = current - 1;
			offset = offset - perPage;
		}

		pagination.data('current', current);
		pagination.data('offset', offset);

		// if (offset>0) {
		// 	offset = offset - perPage;
		// 	pagination.data('offset', offset);
		// 	if (current > 1) {
		// 		current = current-1;
		// 		pagination.data('current', current);
		// 	}
		// }
	} else if ($(this).hasClass('js-gallery-next')) {

		if(current == pages) {
			current = 1;
			offset = 0;
		} else {
			current = current + 1;
			offset = offset + perPage;
		}

		pagination.data('current', current);
		pagination.data('offset', offset);

		// if ((offset + perPage) < total) {
		// 	offset = offset + perPage;
		// 	pagination.data('offset', offset);
		// 	if (current < pages) {
		// 		current = current+1;
		// 		pagination.data('current', current);
		// 	}
		// }
	} else {
		offset = ($(this).data('page')-1) * perPage;
		pagination.data('offset', offset);
		current = $(this).data('page');
		pagination.data('current', current);
	}


	$(".pagination-btn").removeClass('pagination-active');
	$.each($(".pagination-btn"), function (key, button) {
		// console.log( $(this).data('page')) ;
		if ($(this).data('page') &&  $(this).data('page') == current) {

			$(this).addClass('pagination-active');
		}
	});
	// console.log( offset );
	$.ajax({
	    url: '/photos/paginate',
	    type: 'POST',
	    data: {offset: offset},
	    success: function( response ) {
	        response = JSON.parse( response );
	   //      if (response.status == 'success') {
	   //      	$(".gallery-wrapper").empty();
	   //      	$.each(response.photos.photos, function (key, photo) {
	   //      		var name = photo.name ? photo.name : '';
	   //      		var html = '';
	   //      		html+= '<div class="col-6 col-md-3 px-0">';
	   //      		html+= '<a href="'+ photo.photo_path +'" title="'+ name +'" data-name="'+ photo.full_name +'">';
	   //      		html+= '<div class="gallery-item">';
	   //      		html+= '<img class="img-responsive" src="'+ photo.photo_path +'" alt="">';
	   //      		// html+= '<div class="person-hashtag">#smilewithdaikin</div>';
	   //      		html+= '</div>';
	   //      		html+= '</a>';
	   //      		html+= '</div>';
	   //      		$(".gallery-wrapper").append(html);
	   //      	});
	   			if (pages > 5) {
	   				html= '<div class="row">';
	        		html+= '<div class="col-12">';
	        		var number_of_pages = Math.ceil(response.photos.total/8);
	        		html+= '<div class="pagination" data-offset="' + offset + '" data-pages="'+ number_of_pages +'" data-current="' + current + '">';
	        		html+= '<ul class="row mx-auto">';
	        		html+= '<li class="pagination-btn js-gallery-prev" data-direction="prev"><img src="source/images/pagination-left.svg" alt=""></li>';

	        		if(current > 2 && (current < number_of_pages-1)) {
	        			for (var page = current-1; page <= current+1; page++) {
		        			var active = '';
		        			if (page == current) {
		        				active = 'pagination-active';
		        			}
		        			html+= '<li class="pagination-btn '+ active +'" data-page="'+ page +'">'+ page +'</li>';
		        		}
		        		var active = '';
	        			if (pages == current) {
	        				active = 'pagination-active';
	        			}

		        		html+= '<li>...</li>';
		        		html+= '<li class="pagination-btn '+ active +'" data-page="'+ pages +'">'+ pages +'</li>';
	        		} else if(current <= 2) {
	        			for (var page = 1; page <= 3; page++) {
		        			var active = '';
		        			if (page == current) {
		        				active = 'pagination-active';
		        			}
		        			html+= '<li class="pagination-btn '+ active +'" data-page="'+ page +'">'+ page +'</li>';
		        		}
		        		var active = '';
	        			if (pages == current) {
	        				active = 'pagination-active';
	        			}

		        		html+= '<li>...</li>';
		        		html+= '<li class="pagination-btn '+ active +'" data-page="'+ pages +'">'+ pages +'</li>';
	        		} else {
	        			var active = '';
	        			if (1 == current) {
	        				active = 'pagination-active';
	        			}

	        			html+= '<li class="pagination-btn '+ active +'" data-page="1">1</li>';
	        			html+= '<li>...</li>';

	        			for (var page = pages-2; page <= pages; page++) {
		        			var active = '';
		        			if (page == current) {
		        				active = 'pagination-active';
		        			}
		        			html+= '<li class="pagination-btn '+ active +'" data-page="'+ page +'">'+ page +'</li>';
		        		}
	        		}

	        		// for (var page = 1; page <= 3; page++) {
	        		// 	var active = '';
	        		// 	if (page == current) {
	        		// 		active = 'pagination-active';
	        		// 	}
	        		// 	html+= '<li class="pagination-btn '+ active +'" data-page="'+ page +'">'+ page +'</li>';
	        		// }
	        		// active = '';
	        		// if (current == pages) {
	        		// 	active = 'pagination-active';
	        		// }
	        		// html+= '<li>...</li>';
	        		// html+= '<li class="pagination-btn '+ active +'" data-page="'+ pages +'">'+ pages +'</li>';
					html+= '<li class="pagination-btn js-gallery-next" data-direction="next"><img src="source/images/pagination-right.svg" alt=""></li>';
					html+='</ul></div></div></div>';
	   			} else {
	   				html= '<div class="row">';
	        		html+= '<div class="col-12">';
	        		var number_of_pages = Math.ceil(response.photos.total/8);
	        		html+= '<div class="pagination" data-offset="' + offset + '" data-pages="'+ number_of_pages +'" data-current="' + current + '">';
	        		html+= '<ul class="row mx-auto">';
	        		html+= '<li class="pagination-btn js-gallery-prev" data-direction="prev"><img src="source/images/pagination-left.svg" alt=""></li>';
	        		
	        		for (var page = 1; page <= number_of_pages; page++) {
	        			var active = '';
	        			if (page == current) {
	        				active = 'pagination-active';
	        			}
	        			html+= '<li class="pagination-btn '+ active +'" data-page="'+ page +'">'+ page +'</li>';
	        		}
					html+= '<li class="pagination-btn js-gallery-next" data-direction="next"><img src="source/images/pagination-right.svg" alt=""></li>';
					html+='</ul></div></div></div>';
	   			}
	        	
				$(".pagination-wrapper").html(html);
    //     }
    		$(".gallery-wrapper").empty();
    		$(".gallery-wrapper").html(response.gallery);
    		
    		// $(".pagination-wrapper").empty();
    		// $(".pagination-wrapper").html(response.paginate);
	    },
	    error: function (xhr, status, error) {
	        console.log( xhr.responseText );
	    }
	});
});