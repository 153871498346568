// ony ios users in in-app fb browser can take photos


if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/FBAV/i)) {
    $("#ios-overlay").removeAttr('style');

    $("#ios-overlay").on('click', function (e) {
    	e.preventDefault();
    	$('#ios-photo').prop('value', '');
    	$("#ios-photo").click();
    });
}


$(document).on('click', '#load-overlay', function(e) {
	e.preventDefault();
	$('#photo').prop('value', '');
	$("#photo").click();
});