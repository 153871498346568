$(document).on('click', '#draw-winner', function(e) {
	e.preventDefault();
	var winnerName = $("#winner-name");
	winnerName.empty();

	$.ajax({
	    url: 'draw-winner',
	    type: 'POST',
	    success: function( response ) {
	        response = JSON.parse( response );
	        if (response.status == 'success') {
	        	winnerName.text(response.winner.full_name);
	        }
	    },
	    error: function (xhr, status, error) {
	        console.log( xhr.responseText );
	    }
	});
});