// register user ajax
$(".app-to-step-6").on('click', function (e) {
	e.preventDefault();

	var formMessage = $(".form-message");
	formMessage.empty();

	var photo = $("#photo-preview").data('relative-url');
	
	if (!photo) {
		formMessage.append('<p>Došlo je do pogreške,pokušajte ponovo</p>');
		return;
	}

	var errors = 0;
	var fullName = $("#full-name").val();
	if(!fullName) {
		errors++;
		formMessage.append('<p>Unesi ime i prezime</p>');
	}
	var email = $("#email").val();
	if(!email) {
		errors++; 
		formMessage.append('<p>Unesi email adresu</p>');
	} else {
		email = $.trim(email);
		var emailCheck = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+$/;
		if(!emailCheck.test(email)) {
			errors++;
			formMessage.append('<p>Unesi ispravnu email adresu</p>');
		}
	}
	var phone = $("#phone").val();
	var phone = phone.replace(/\s+/g, '');
	if(!phone || phone == '+385') {
		errors++; 
		formMessage.append('<p>Unesi broj telefona</p>');
	}
	
	  if (phone != '+385' && phone != '') {
	      if (!regexValidation(phone, '^[+]{0,1}[(]{0,1}[0-9]{3}[)]{0,1}[0-9]{6,10}$')) {
	      	  console.log( 'unutra' );
	          errors++;
	          formMessage.append('<p>Unesi ispravan broj telefona</p>');
	      }
	   }
	var conditions = $("#accept-conditions").is(":checked");
	if (!conditions) {
		errors++;
		formMessage.append('<p>Prihvati uvjete sudjelovanja u nagradnoj igri</p>');
	}
	var ageCheck = $("#age-check").is(":checked");
	if (!ageCheck) {
		errors++;
		formMessage.append('<p>Samo punoljetne osobe smiju sudjelovati u nagradnoj igri</p>');
	}

	if (errors>0) {
		return;
	}

	var shortName = $("#short-name").val();
	var temp = $("#ideal-temp").val();
	var smile = $(".number-to-animate").text();

	var data = {
		'photo': photo,
		'full_name': fullName,
		'email': email,
		'phone': phone,
		'conditions': conditions,
		'ageCheck': ageCheck,
		'shortName': shortName,
		'temp': temp,
		'smile': smile,
	};

	// var homeUrl = window.location.protocol + "//" + window.location.host + "/";
	$.ajax({
	    url: '/participants/insert',
	    type: 'POST',
	    data: data,
	    beforeSend: function() {
	    	$(".app-to-step-6").off("click");
	    },
	    success: function( response ) {
	    	response = JSON.parse(response);
			// next slide
	        if (response.status == 'success') {
	        	if (response.email) {
	        		$("#fb-share").attr('data-email', response.email);
	        	}
	        	$("#person-name").text(fullName);
	        	var owl = $('.owl-carousel');

				  owl.owlCarousel({
				    items: 1,
				    autoHeight: true,
				    touchDrag: false,
				    pullDrag: false,
				    mouseDrag: false,
				    freeDrag: false
				  });
	        	owl.trigger('next.owl.carousel');

	        	$.ajax({
	        		url: '/gallery',
	        		type: 'GET',
	        		success: function( response ) {
	        			response = JSON.parse(response);
	        			$('.gallery-wrapper').html(response.gallery);
	        			$('.paginate-wrapper').html(response.paginate);
	        		}
	        	});

	        	var lastScrollTop = 0;
				$(window).scroll(function(event){
				   var st = $(this).scrollTop();
				   if (st != lastScrollTop){
				      	$(".trouble-report-app").addClass('trouble-report').removeClass('trouble-report-app');
				   }
				   lastScrollTop = st;
				});
	        } else {
	        	formMessage.empty();
	        	formMessage.append('<p>'+ response.message +'</p>');
	        }
	    },
	    error: function (xhr, status, error) {
	        console.log( xhr.responseText );
	    },
	    complete: function() {
	    	$(".app-to-step-6").on("click");
	    },
	});


});


function regexValidation(value, regex) {
  if (!value) {
    return false;
  }
  var regex = new RegExp(regex);
  if (value.match(regex)) {
    return true;
  }
  return false;
}