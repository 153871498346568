$(document).on('click', '#go-back', function(e) {
	e.preventDefault();
	
	var owl = $('.owl-carousel');

	  owl.owlCarousel({
	    items: 1,
	    autoHeight: true,
	    touchDrag: false,
	    pullDrag: false,
	    mouseDrag: false,
	    freeDrag: false
	  });
	owl.trigger('prev.owl.carousel');

	var photo_path = $("#photo-preview").data('relative-url');
	if (!photo_path) {
		console.log( 'File not found' );
		return;
	}
	$.ajax({
	    url: '/photos/delete',
	    type: 'POST',
	    data: {photo_path: photo_path},
	    success: function( response ) {
	        // response = JSON.parse( response );
	        console.log(typeof response);
	        console.log(response);
	    },
	    error: function (xhr, status, error) {
	        console.log( xhr.responseText );
	    }
	});
});