$(document).ready(function () {

    if (location.hash == '#ad') {
        $('html, body').animate({
            scrollTop: $("#a-sad").offset().top
        }, 1);
    }
    $('.popup-gallery').magnificPopup({
        closeMarkup: '<span class="mfp-close icon-cancel close-popup"><svg version="1.1" width="14px" height="14px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 212.982 212.982" style="enable-background:new 0 0 212.982 212.982;" xml:space="preserve"><g><path style="fill-rule:evenodd;clip-rule:evenodd;" d="M131.804,106.491l75.936-75.936c6.99-6.99,6.99-18.323,0-25.312c-6.99-6.99-18.322-6.99-25.312,0l-75.937,75.937L30.554,5.242c-6.99-6.99-18.322-6.99-25.312,0c-6.989,6.99-6.989,18.323,0,25.312l75.937,75.936L5.242,182.427c-6.989,6.99-6.989,18.323,0,25.312c6.99,6.99,18.322,6.99,25.312,0l75.937-75.937l75.937,75.937c6.989,6.99,18.322,6.99,25.312,0c6.99-6.99,6.99-18.322,0-25.312L131.804,106.491z"/></g></svg></span>',
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            markup: '<div class="mfp-figure">' +
                '<span class="mfp-close icon-cancel close-popup"><svg version="1.1" width="14px" height="14px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 212.982 212.982" style="enable-background:new 0 0 212.982 212.982;" xml:space="preserve"><g><path style="fill-rule:evenodd;clip-rule:evenodd;" d="M131.804,106.491l75.936-75.936c6.99-6.99,6.99-18.323,0-25.312c-6.99-6.99-18.322-6.99-25.312,0l-75.937,75.937L30.554,5.242c-6.99-6.99-18.322-6.99-25.312,0c-6.989,6.99-6.989,18.323,0,25.312l75.937,75.936L5.242,182.427c-6.989,6.99-6.989,18.323,0,25.312c6.99,6.99,18.322,6.99,25.312,0l75.937-75.937l75.937,75.937c6.989,6.99,18.322,6.99,25.312,0c6.99-6.99,6.99-18.322,0-25.312L131.804,106.491z"/></g></svg></span>' +
                '<div class="mfp-img"></div>' +
                '</div>',
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            // titleSrc: function(item) {
            // 	return item.el.attr('title');
            // }
        }
    });


    if ($('.section-game').children().first().hasClass('game-slide')) {
        // adds ids to pages to slide up
        var itemsToSlideUp = $('.game-slide').length;
        var itemsSlideDecrement = itemsToSlideUp;

        for (var i = 0; i < itemsToSlideUp; i++) {
            $('.game-slide').eq(i).attr('id', 'js-slide-left-' + (itemsSlideDecrement));
            $('#js-slide-left-' + (itemsSlideDecrement)).css('z-index', 20 + itemsSlideDecrement);
            itemsSlideDecrement--;
        }
    }

    //temperature slider
    var slider = $("#ideal-temp");
    var output = $("#cur-temp");
    output.html(slider.val() + '.0°C'); // Display the default slider value

    // Update the current slider value (each time you drag the slider handle)
    $(slider).on('input', function (e) {
        e.preventDefault();

        var c = '°C';
        if (parseFloat(slider.val()) - Math.floor(slider.val()) == 0) {
            console.log(parseFloat(slider.val()) - Math.floor(slider.val()) == 0);
            // console.log( parseFloat(slider.val()) - Math.floor(slider.val() == 0) );
            c = '.0°C'
        }
        output.html(slider.val() + c);
    });
});

$(".choice.red .choice-btn").click(function () {
    $(".submit1").addClass('inactive')
    $("#submit-1").removeClass('inactive')
    setTimeout(function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#submit-1").offset().top - 20
        }, 1000);
    }, 300);
});

$(".choice.green .choice-btn").click(function () {
    $(".submit1").addClass('inactive')
    $("#submit-2").removeClass('inactive')
    setTimeout(function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#submit-2").offset().top - 20
        }, 1000);
    }, 300);

});

$(".choice.blue .choice-btn").click(function () {
    $(".submit1").addClass('inactive')
    $("#submit-3").removeClass('inactive')

    setTimeout(function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#submit-3").offset().top - 20
        }, 1000);
    }, 300);

});

$("#submit-1 .back-btn").click(function () {


    setTimeout(function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".red").offset().top - 50
        }, 1000);
    }, 0);

});

$("#submit-2 .back-btn").click(function () {
    setTimeout(function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".green").offset().top - 50
        }, 1000);
    }, 0);
});
$("#submit-3 .back-btn").click(function () {
    setTimeout(function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".blue").offset().top - 50
        }, 1000);
    }, 0);
});

$(".back-btn ").click(function () {
    setTimeout(function () {

        $(".submit1").addClass('inactive')
    }, 400);

});

$(".js-scroll-to-step2").click(function () {
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".section-green").offset().top + 60
    }, 2000);
});

$(".js-scroll-to-step3").click(function () {
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".section-prizes").offset().top + 60
    }, 1500);
});

$(".js-run-game").click(function () {
    $(".section-game").slideDown(500);
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".section-game").offset().top
    }, 1500);
    $(".trouble-report").addClass('trouble-report-app').removeClass('trouble-report');
    // $(".trouble-report").hide();
});

// $('.app-to-step-2').click(function() {
//     $('.js-slide-1').addClass('slide-out');
// });


$(document).ready(function () {
    var owl = $('.owl-carousel');

    owl.owlCarousel({
        items: 1,
        autoHeight: true,
        touchDrag: false,
        pullDrag: false,
        mouseDrag: false,
        freeDrag: false
    });

    $('.app-to-step-2').click(function () {
        owl.trigger('next.owl.carousel');
    });

    $('.section-game').on('click', '.button', function () {
        owl.trigger('refresh.owl.carousel');
    });

    $(".owl-stage .owl-item:nth-child(2)").css('background', '#007bff');
});


$('.app-to-step-3').click(function () {
    if ($("#short-name").val().length >= 2 && $("#short-name").val().length <= 20) {
        var error = $("#name-error");
        // validation
        var name = $("#short-name").val();
        var temp = $("#ideal-temp").val();
        if (!name || !temp) {
            error.empty();
            error.append('<p>Došlo je do pogreške, pokušaj ponovo</p>');
            return;
        }
        $(".js-text-name").text(name);
        $(".js-text-temp").text(temp + '°C');
        $('.owl-carousel').trigger('next.owl.carousel');

        // $('.js-slide-2').addClass('slide-out');

        // var data = new FormData();
        // data.append('photo', $('.photo')[0].files[0]);
        // data.append('name', name);
        // data.append('temp', temp);

        // var homeUrl = window.location.protocol + "//" + window.location.host + "/";
        // $.ajax({
        //     url: '/photos/upload/',
        //     type: 'POST',
        //     data: data,
        //       	contentType: false,
        //       	processData: false,
        //     success: function( response ) {
        //     	response = JSON.parse(response);
        //         if (response.status == 'success') {
        //         	$("#photo-preview").attr("src", homeUrl+response.path);
        //         	$("#photo-preview").data('relative-url', response.path);
        //         	$("#photo-preview").width(768).height(768);
        //         	$('.owl-carousel').trigger('next.owl.carousel');
        //         	// $('.js-slide-2').addClass('slide-out');
        //         } else {
        //         	error.empty();
        // 			error.append('<p>'+ response.message +'</p>');
        //         }
        //     },
        //     error: function (xhr, status, error) {
        //         console.log( xhr.responseText );
        //     }
        // });

    } else {
        $(".form-message").empty();
        if (!$("#short-name").val()) {
            $("#name-error").append('<p>Molimo unesi ime</p>');
        } else if ($("#short-name").val().length < 2) {
            $("#name-error").append('<p>Ime je prekratko</p>');
        } else if ($("#short-name").val().length >= 20) {
            $("#name-error").append('<p>Ime je predugo</p>');
        }
    }
});

function animateSmileCounter(percentage) {
    var percentToAnimate = Math.round(percentage);
    var animateProgress = $('.animate-progress');
    var animateProgressParams = $('.animate-progress-params');

    animateProgress.addClass('animate-progress-params');
    calculated = 275 / 100 * percentToAnimate;

    animateProgress.css('stroke-dasharray', calculated + ' ' + 276);
    animateProgress.css('stroke-dashoffset', calculated);
    animateProgressParams.css('stroke-dashoffset', calculated);

    $('.number-to-animate').prop('Counter', 0).animate({
        Counter: $('.number-to-animate').text()
    }, {
        duration: 4000,
        easing: 'swing',
        step: function (now) {
            $('.number-to-animate').text(Math.round(now));
        }
    });
}

// $('.app-to-step-4').click(function() {
//  $('.js-slide-3').addClass('slide-out');

//  animateSmileCounter();

//  setTimeout(function() {
// 	 $('.smile-measure-btns').fadeIn();
// 	 $('.loader-text').text('Tvoj osmijeh je na:');

//  }, 4500);

// 	var percentToAnimate = parseInt($(".number-to-animate").text());
// 	var animateProgress = $('.animate-progress');
// 	var animateProgressParams = $('.animate-progress-params');

// 	animateProgress.addClass('animate-progress-params');
// 	calculated = 275 / 100 * percentToAnimate;

// 	animateProgress.css('stroke-dasharray', calculated + ' ' + 276);
// 	animateProgress.css('stroke-dashoffset', calculated);
// 	animateProgressParams.css('stroke-dashoffset', calculated);

// 	$('.number-to-animate').prop('Counter',0).animate({
// 		Counter: $('.number-to-animate').text()
// 	}, {
// 		duration: 4000,
// 		easing: 'swing',
// 		step: function (now) {
// 			$('.number-to-animate').text(Math.ceil(now));
// 		}
// 	});

// });

$('.app-to-step-5').click(function () {
    //$('.js-slide-4').addClass('slide-out');
    $('.owl-carousel').trigger('next.owl.carousel');
});

$('.more-options-on-md').click(function () {
    $('.thankyou-title').fadeOut();
    $('.last-ste-fadout').fadeOut();
    $('.more-options-on-md').fadeOut();
    $('.fb-box').fadeIn();
});


$('.fb-deny').on('click', function (event) {
    event.preventDefault();

    $([document.documentElement, document.body]).animate({
        scrollTop: $(".section-gallery").offset().top + 60
    }, 2000);
});

var imagePath;
// $('.app-to-step-6').click(function() {
//     $(".trouble-report-app").addClass('trouble-report').removeClass('trouble-report-app');
// });
$(document).on('change', '.photo', function (e) {
    $('.rotate-sun.image-loader').removeClass('d-none');
    $('.app-to-step-4 span').addClass('d-none');
    // $("#btnSubmit").attr("disabled", true);
    var error = $("#name-error");
    // validation
    var name = $("#short-name").val();
    var temp = $("#ideal-temp").val();
    if (!name || !temp) {
        error.empty();
        error.append('<p>Došlo je do pogreške, pokušajte ponovo</p>');
        return;
    }
    console.log('change');
    var data = new FormData();
    data.append('photo', $(this)[0].files[0]);
    data.append('name', name);
    data.append('temp', temp);

    var picture = $(this)[0].files[0];
    EXIF.getData(picture, function () {
        var allMetaData = EXIF.getAllTags(this);
        var orientation = allMetaData.Orientation;
        data.append('orientation', orientation);
        var homeUrl = window.location.protocol + "//" + window.location.host + "/";
        $.ajax({
            url: '/photos/upload/',
            type: 'POST',
            data: data,
            contentType: false,
            processData: false,
            success: function (response) {
                console.log(response);
                response = JSON.parse(response);
                if (response.status == 'success') {
                    $('.rotate-sun.image-loader').addClass('d-none');
                    $('.app-to-step-4 span').removeClass('d-none');
                    $("#photo-preview").removeAttr("style")
                    $("#photo-preview").attr("style", "background-image:url('" + homeUrl + response.path + "')");
                    $("#photo-final").attr('src', homeUrl + response.path);
                    $("#photo-preview").data('relative-url', response.path);
                    $("#photo-preview").width(768).height(768);
                    $(".number-to-animate").text(response.smilePercent);
                    $(".final-percentage").text(Math.round(response.smilePercent));
                    var tempImagePath = response.path;
                    imagePath = homeUrl + 'view' + tempImagePath.split(".")[0];
                    $('.owl-carousel').trigger('next.owl.carousel');
                    //$('.js-slide-3').addClass('slide-out');
                    animateSmileCounter(response.smilePercent);

                    setTimeout(function () {
                        $('.smile-measure-btns').css('display', 'flex');
                        $('.loader-text').text('Tvoj osmijeh je na:');

                    }, 4500);

                    //      	var lastScrollTop = 0;
                    // $(window).scroll(function(event){
                    //    var st = $(this).scrollTop();
                    //    if (st != lastScrollTop){
                    //       	$(".trouble-report-app").addClass('trouble-report').removeClass('trouble-report-app');
                    //    }
                    //    lastScrollTop = st;
                    // });
                } else {
                    error.empty();
                    error.append('<p>' + response.message + '</p>');
                }
            },
            error: function (xhr, status, error) {
                console.log(xhr.responseText);

            }
        });
    });

});
// $(".trouble-report").hide();
var footerHeight = $(".footer-lower-section").height();
$(window).scroll(function () {
    if ($(".trouble-report-app").hasClass('trouble-report-app')) {
        if ($(window).scrollTop() + $(window).height() > $(document).height() - footerHeight) {
            var px = footerHeight + 'px';
            $(".trouble-report-app").css({bottom: px});
        } else {
            $(".trouble-report-app").css({bottom: 0});
        }
    }
});

$("#go-foward").on('click', function (e) {
    e.preventDefault();
    $('.js-slide-4').addClass('slide-out');
});

$('#toggle-sidebar').on('click', function () {
    $('.nav-list-items').toggleClass('increase-nav-height');
});

$('#toggle-sidebar').on('click', function () {
    console.log('okida');
    /* $('.nav-list-items').toggle();
    $('.nav-list-items').slideToggle(300); */
    $('.hamburger span').toggleClass('animate-hamburger');
    $('.hamburger span').toggleClass('w-0');
});

$('.cookie-consent-agree').on('click', function (event) {
    event.preventDefault();

    setCookie('cookie-consent', true, 30);
    $('.cookie-consent').hide();
});

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

//
// $(window).scroll(function () {
//
//     var elem = $('.abs-img-green');
//     var docViewTop = $(window).scrollTop();
//     var docViewBottom = docViewTop + $(window).height();
//
//     var elemTop = $(elem).offset().top - $(window).height();
//     var elemBottom = elemTop + $(elem).height();
//     var elemCenter = elemTop + ($(elem).height() / 2);
//
//     if ($(window).width() > 1024) {
//         if (docViewBottom > elemCenter) {
//             elem.addClass('grow-dog');
//         }
//
//         if ($(window).width() >= 1200) {
//             if (docViewTop > 1000) {
//                 $('.section-intro .container-fluid').css('position', 'absolute');
//             } else {
//                 $('.section-intro .container-fluid').css('position', 'fixed');
//             }
//         }
//     }
// });

function startWinner() {
    $('.draw-step-1').fadeOut(600);

    setTimeout(function () {
        $('.draw-step-2').css("display", "flex").hide().fadeIn(600);
        $('.drawing-progress-animated').css('width', '100%');
        $('.skew-bg-1').css('background-image', 'radial-gradient(circle at 9% 0, #9d62c8, #ba7ddd)');
        $('.drawing-bg-general').css('background-image', 'linear-gradient(to right, #f5b24b, #e69703)');
    }, 600);


    setTimeout(function () {
        $('.draw-step-2').fadeOut(600);

        setTimeout(function () {
            $('.skew-bg-1').css('background-image', 'none');
            $('.drawing-bg-general').css({
                'background': 'linear-gradient(0deg, rgba(244, 199, 0, 0.8), rgba(229, 149, 0, 0.8)), url(dist/images/drawing-smilies.png) center center / cover no-repeat',
                'background-image': 'linear-gradient(to right, rgb(244, 199, 0), rgb(229, 149, 0));'
            });
            $('.draw-step-3').css("display", "flex").hide().fadeIn(600);
            $.confetti.restart();
        }, 600);

    }, 4600);

}

function backToDrawStart() {
    $('.draw-step-3').fadeOut(600);

    setTimeout(function () {
        $('.draw-step-1').fadeIn(600);
        $('.skew-bg-1').css('background-image', 'radial-gradient(circle at 9% 0, #009ad9, #0978a6)');
        $('.drawing-bg-general').css('background-image', 'linear-gradient(to right, #9fc162, #8dae65)');
        $('.drawing-progress-animated').css('width', '0');
        $.confetti.stop();
    }, 600);

}

$(document).keyup(function (e) {
    if (e.key === "Escape") {
        backToDrawStart();
    }
});

$('.js-to-draw-step-2').on('click', function () {
    startWinner();
});


$(document).keydown(function (e) {
    if (e.keyCode == '32') {
        startWinner();
    }
});


AOS.init({once: true});




// NEW BACKEND JS
//
function makeid(length) {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}


function addFiles(form) {

    var currentFiles = $('#' + form + '-files').prop('files');
    var nameSpan = '';
    $.each(currentFiles, function (key, file) {

        var sufix = '_' + makeid(3);
        var fffname = file.name;
        fffname = fffname.replace(/(\.[\w\d_-]+)$/i, sufix+'$1');

        nameSpan = ' <span class="f-name" data-file="' + fffname + '"  data-form="' + form + '">' + fffname + ' <span class="delete-file"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">\n' +
            '    <g fill="none" fill-rule="evenodd">\n' +
            '        <g>\n' +
            '            <g transform="translate(-1208 -50) translate(1208 50)">\n' +
            '                <circle cx="7.5" cy="7.5" r="7.5" fill="white"/>\n' +
            '                <g fill="#020202">\n' +
            '                    <path d="M4 0H5V9H4z" transform="rotate(45 2.379 9.621)"/>\n' +
            '                    <path d="M4 0H5V9H4z" transform="rotate(45 2.379 9.621) rotate(90 4.5 4.5)"/>\n' +
            '                </g>\n' +
            '            </g>\n' +
            '        </g>\n' +
            '    </g>\n' +
            '</svg>\n</span> <br> </span>';

        if (form == 'return') {
            console.log( 'inside' );
            if ($('.f-name[data-file="' + fffname + '"][data-form="' + form + '"]').length == 0) {
                $("#submit-1 .file-names").append(nameSpan);
                returnFiles[fffname] = file;
                console.log('return files added', returnFiles);
            }
        } else {
            console.log( 'inside2' );
            if ($('.f-name[data-file="' + fffname + '"][data-form="' + form + '"]').length == 0) {
                $("#submit-2 .file-names").append(nameSpan);
                replaceFiles[fffname] = file;
                console.log('replace files added', replaceFiles);
            }
        }
    });

}

function removeFiles(form, filename) {

    $('.f-name[data-file="' + filename + '"][data-form="' + form + '"]').remove();
    if (form == 'return') {
        delete returnFiles[filename];
        console.log(returnFiles);
    } else {
        delete replaceFiles[filename];
        console.log(replaceFiles);
    }

}

function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf('Instagram') > -1);
}

// ADD && REMOVE UPLOADED FILES
var returnFiles = [];
var replaceFiles = [];
// return
$('#submit-1 .file-upload').bind('change', function () {

    addFiles('return');
    $(this).val('');

    if(isFacebookApp()){
        setTimeout(function () {
            $([document.documentElement, document.body]).animate({
            scrollTop: $("#submit-1 .files-label").offset().top - 100
        }, 0);
        }, 100);
    }


});
// replace
$('#submit-2 .file-upload').bind('change', function () {
    addFiles('replace');
    $(this).val('');

    if(isFacebookApp()){
        setTimeout(function () {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#submit-2 .files-label").offset().top - 100
            }, 0);
        }, 100);
    }
});

$(document).on('click', '.f-name', function (e) {
    e.preventDefault();

    removeFiles($(this).data('form'), $(this).data('file'));

});

if (window.document.documentMode) {
    $(".ie").removeClass("hide");
    $("section").remove();
    $("nav").remove();


}

$(document).on('click', '.saznaj', function (e) {
    fbq('track', 'clickSBM');
});
$(document).on('click', '.asistent', function (e) {
    fbq('track', 'clickAsistent');
});
$(document).on('click', '.kvaliteta', function (e) {
    fbq('track', 'clickKvaliteta');
});
$(document).on('click', '.ponuda', function (e) {
    fbq('track', 'clickPonuda');
});
$(document).on('click', '.partneri', function (e) {
    fbq('track', 'clickPartneri');
});



function isEmpty( el ){
    return !$.trim(el.html())
}
if (isEmpty($('#element'))) {
    // do something
}

// submit form
$(document).on('submit', '.participant-form', function (e) {
    e.preventDefault();

    var button = $(this).find('.submit-form');
    button.prop("disabled", true);
    $(".form-notice").empty();

    var form = button.data('form');
    if (form != 'replace' && form != 'return' && form != 'play') {
        console.log('No selected form', form);
        button.prop("disabled", false);
        return;
    }

    var errors = false;

    var data = new FormData();
    var name = '';
    var email = '';
    var terms = '';
    if (form == 'play') {
        $('#submit-3 .btn-holder').before('<div class="lds-ring"><div></div><div></div><div></div><div></div></div>')
        name = $("#play-name").val();
        email = $("#play-email").val();
        terms = $("#play-terms").is(':checked');

        toggleField(name.length < 1, $("#play-name"));

        if (!terms) {
            $(".play-label.required").addClass('invalid');
        } else {
            $(".play-label.required").removeClass('invalid')
        }
        toggleField(!isValidEmail(email), $("#" + form + "-email"));

        if (name && isValidEmail(email) && terms) {
            data.append('name', name);
            data.append('email', email);
            data.append('terms', terms);
            data.append('contact', $("#play-contact").is(':checked'));
        } else {
            errors = true;
        }

    } else if (form == 'replace') {
        $('#submit-2 .btn-holder').before('<div class="lds-ring"><div></div><div></div><div></div><div></div></div>')

        name = $("#replace-name").val();
        email = $("#replace-email").val();
        terms = $("#replace-terms").is(':checked');
        var contact = $("#replace-contact").is(':checked');
        var modelInfo = $("#replace-model-info").val();
        var address = $("#replace-address").val();

        toggleField(name.length < 1, $("#replace-name"));
        toggleField(address.length < 1, $("#replace-address"));
        toggleField(modelInfo.length < 1, $("#replace-model-info"));
        if (!terms) {
            $(".replace-label").addClass('invalid');
        } else {
            $(".replace-label").removeClass('invalid');
        }
        if(isEmpty($('#submit-2 .file-names'))){
            $('#submit-2 .files-label').css('border', 'dashed 2px red')
        }else{
            $('#submit-2 .files-label').css('border', 'dashed 2px white')
        }
        toggleField(!isValidEmail(email), $("#" + form + "-email"));

        if (name && isValidEmail(email) && terms && modelInfo && address) {
            data.append('name', name);
            data.append('email', email);
            data.append('terms', terms);
            data.append('modelInfo', modelInfo);
            data.append('address', address);
            data.append('contact', contact);
        } else {
            errors = true;
        }

    } else if (form == 'return') {
        $('#submit-1 .btn-holder').before('<div class="lds-ring"><div></div><div></div><div></div><div></div></div>')

        name = $("#return-name").val();
        email = $("#return-email").val();
        terms = $("#return-terms").is(':checked');
        var contact = $("#return-contact").is(':checked');
        var buyDate = $("#return-buy-date").val();
        var model = $("#return-model").val();
        var partner = $("#return-partner").val();

        toggleField(name.length < 1, $("#return-name"), 1);
        toggleField(model.length < 1, $("#return-model"), 1);
        toggleField(buyDate.length < 1, $("#return-buy-date"), 1);
        toggleField(partner.length < 1, $("#return-partner"), 1);

        if (!terms) {
            $(".return-label").addClass('invalid');
        } else {
            $(".return-label").removeClass('invalid');
        }

        if(isEmpty($('#submit-1 .file-names'))){
            $('#submit-1 .files-label').css('border', 'dashed 2px yellow')
        }else{
            $('#submit-1 .files-label').css('border', 'dashed 2px white')
        }

        toggleField(!isValidEmail(email), $("#" + form + "-email"), 1);

        if (name && isValidEmail(email) && terms && model && partner) {
            data.append('name', name);
            data.append('email', email);
            data.append('terms', terms);
            data.append('model', model);
            data.append('partner', partner);
            data.append('buyDate', buyDate);
            data.append('contact', contact);
        } else {
            errors = true;
        }

    } else {
        console.log('Data error');
        displayNotice(form, 'Došlo je do pogreške');
        $('.lds-ring').remove();
        button.prop("disabled", false);
        return;
    }

    if (errors) {
        displayNotice(form, 'Molimo da popunite sva potrebna polja u ispravnom formatu.')
        $('.lds-ring').remove();
        button.prop("disabled", false);
        return;
    }

    data.append('type', form);
    console.log('test0');

    // validate files
    if (form == 'return' || form == 'replace') {

        var fileArray = [];
        if (form == 'return') {
            fileArray = returnFiles;
        } else {
            fileArray = replaceFiles;
        }

        var files = [];
        for (var key in fileArray) {
            files.push(fileArray[key]);
            console.log(fileArray[key]);
        }
        console.log('FILES', files);
        console.log('test1');

        if (files.length == 0) {
            console.log('test');
            displayNotice(form, 'Molimo učitajte potrebne datoteke.');


            $('.lds-ring').remove();

            button.prop("disabled", false);
            return;
        }
        if (files.length > 6) {
            displayNotice(form, 'Dozvoljeno je učitati najviše 6 datoteka.');

            $('.lds-ring').remove();
            button.prop("disabled", false);
            return;
        }

        if (typeof files != 'undefined' && files.length > 0) {
            var totalSize = 0;
            $.each(files, function (key, file) {
                data.append('files[]', file);
                totalSize = totalSize + file.size;
            });
            if (totalSize != 0) {
                totalSize = Math.floor(totalSize / 1000000);
                var mbLimit = 50;
                if (totalSize > mbLimit) { // limit to 50 mb
                    displayNotice(form, 'Dozvoljeno je učitati najviše ' + mbLimit + ' mb.');
                    $('.lds-ring').remove();
                    button.prop("disabled", false);
                    return;
                }
            }

        }

    }

    $.ajax({
        url: '/submit',
        type: 'POST',
        data: data,
        processData: false,
        cache: false,
        contentType: false,
        enctype: 'multipart/form-data',
        success: function (response) {
            response = JSON.parse(response);
            console.log(typeof response);
            console.log(response);
            if (response.status == 'error') {
                button.prop("disabled", false);
                displayNotice(form, response.message);
                $('.lds-ring').remove();

            } else {
                if (form == 'return') {
                    dataLayer.push({
                        'event': 'GAEventReturn',
                        'eventCategory': 'formSubmit',
                        'eventAction': 'Success'
                    });

                    $('#submit-1 .form').fadeOut();
                    $('#submit-1 .info-row-flex').append('<br> <br> <div class="thanks title-md color-light info-row-title normal-font">Hvala! Tvoja prijava je uspješna.' +
                        '                        </strong></div><div class="regular-text color-light info-row-text">\n' +
                        '                   Svakako prouči našu stranicu, saznaj više o Daikin proizvodima i pričekaj izvlačenje sretnih dobitnika koje će se održati 19.11.2020. godine.\n' +
                        ' <strong> <br> <br>\n' +
                        'Sretne dobitnike ćemo kontaktirati putem maila! ' +
                        '</strong>\n' +
                        '                </div>')
                    setTimeout(function () {
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $("#submit-1 .thanks").offset().top
                        }, 1000);
                    }, 500);

                        fbq('track', 'submitReturn');
                        fbq('track', 'submitGroup');


                } else if (form == 'replace') {
                    dataLayer.push({
                        'event': 'GAEventReplace',
                        'eventCategory': 'formSubmit',
                        'eventAction': 'Success'
                    });
                    $('#submit-2 .form').fadeOut();
                    $('#submit-2 .info-row-flex').append('<br> <br> <div class="thanks title-md color-light info-row-title normal-font">Hvala! Tvoja prijava je uspješna.' +
                        '                        </strong></div><div class="regular-text color-light info-row-text">\n' +
                        '                   Svakako prouči našu stranicu, saznaj više o Daikin proizvodima i pričekaj izvlačenje sretnih dobitnika koje će se održati 19.11.2020. godine.\n' +
                        ' <strong> <br> <br>\n' +
                        'Sretne dobitnike ćemo kontaktirati putem maila! ' +
                        '</strong>\n' +
                        '                </div>')
                    setTimeout(function () {
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $("#submit-2 .thanks").offset().top
                        }, 1000);
                    }, 500);

                    fbq('track', 'submitReplace');
                    fbq('track', 'submitGroup');



                } else if (form == 'play') {
                    dataLayer.push({
                        'event': 'GAEventPlay',
                        'eventCategory': 'formSubmit',
                        'eventAction': 'Success'
                    });
                    $('#submit-3 .form').fadeOut();
                    $('#submit-3 .info-row-flex').append('<br> <br> <div class="thanks title-md color-light info-row-title normal-font">Hvala! Tvoja prijava je uspješna.' +
                        '                        </strong></div><div class="regular-text color-light info-row-text">\n' +
                        '                   Svakako prouči našu stranicu, saznaj više o Daikin proizvodima i pričekaj izvlačenje sretnih dobitnika koje će se održati 19.11.2020. godine.\n' +
                        ' <strong> <br> <br>\n' +
                        'Sretne dobitnike ćemo kontaktirati putem maila! ' +
                        '</strong>\n' +
                        '                </div>')
                    setTimeout(function () {
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $("#submit-3 .thanks").offset().top
                        }, 1000);
                    }, 500);

                    fbq('track', 'submitPlay');
                    fbq('track', 'submitGroup');



                }
            }


        },
        error: function (xhr, status, error) {
            console.log(xhr.responseText);
            displayNotice(form, 'Došlo je do pogreške.');
            $('.lds-ring').remove();
            button.prop("disabled", false);
        }
    });
});


function displayNotice(form, notice) {
    $("#" + form + "-notice").append('<p>' + notice + '</p>');
}

function isValidEmail(email) {
    if (!email) {
        return false;
    }
    if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
        return true;
    } else {
        return false;
    }
}

function toggleField(condition, element, form) {
    if (condition) {
        if (form === 1) {
            element.css('border', '3px solid yellow')
        } else {
            element.css('border', '3px solid red')
        }
    } else {
        element.css('border', 'none')
    }
}
    $.datepicker.regional['hr'] = {clearText: 'izbriši', clearStatus:
            'Izbriši trenutni datum',
        closeText: 'Zatvori', closeStatus: 'Zatvori kalendar',
        prevText: '&#x3c;&#x3c;', prevStatus: 'Prikaži prethodni mjesec',
        nextText: '&#x3e;&#x3e;', nextStatus: 'Prikaži slijedeći mjesec',
        currentText: 'Danas', currentStatus: 'Današnji datum',
        monthNames:
            ['Siječanj','Veljača','Ožujak','Travanj','Svibanj','Lipani',
                'Srpanj','Kolovoz','Rujan','Listopad','Studeni','Prosinac'],
        monthNamesShort: ['Sij','Velj','Ožu','Tra','Svi','Lip',
            'Srp','Kol','Ruj','Lis','Stu','Pro'],
        monthStatus: 'Prikaži mjesece', yearStatus: 'Prikaži godine',
        weekHeader: 'Tje', weekStatus: 'Tjedan',
        dayNames:
            ['Nedjalja','Ponedjeljak','Utorak','Srijeda','Četvrtak','Petak','Subota'],
        dayNamesShort: ['Ned','Pon','Uto','Sri','Čet','Pet','Sub'],
        dayNamesMin: ['Ne','Po','Ut','Sr','Če','Pe','Su'],
        dayStatus: 'Odaber DD za prvi dan tjedna', dateStatus: 'Datum D, Md',
        dateFormat: 'dd.mm.yy.', firstDay: 1,
        initStatus: 'Odaberi datum', isRTL: false};
    $.datepicker.setDefaults($.datepicker.regional['hr']);

$( "#return-buy-date" ).datepicker({
    changeMonth: true,
    changeYear: true,
    maxDate: '+0m +0w',
    // minDate: "-100Y",
    yearRange: "-30:+0"

});
