// share uploaded image for duplication in database

$(document).on('click', '#fb-share', function(e) {
	e.preventDefault();
	console.log(imagePath);
	var formMessage = $(".form-message");
	formMessage.empty();
	var shareBtn = $(this);
	var photo = $("#preview-image").attr('src');

    FB.ui(
	    {
	        method: 'share',
	        mobile_iframe: true,
	        // name: 'Facebook Dialogs',
	        href: imagePath,
	        link: imagePath,
	        picture: photo,
	        // caption: 'Ishelf Book',
	        // description: 'your description'
	    },
	    function (response) {
	        if (response && !response.error_code) {
				if (typeof response != 'undefined'){
					var email = shareBtn.attr('data-email');
					if (!email) {
						formMessage.append('<p>Došlo je do pogreške</p>');
						return;
					}

					// var homeUrl = window.location.protocol + "//" + window.location.host + "/";
					$.ajax({
						url: '/participants/duplicate',
						type: 'POST',
						data: {email: email},
						success: function( response ) {
							response = JSON.parse(response);
							formMessage.empty();
							if (response.status == 'success') {
								//formMessage.append('<p>Uspjeh</p>');
								shareBtn.attr('data-email', '');
								shareBtn.attr("disabled", true);
							} else {
								formMessage.append('<p>Došlo je do pogreške</p>');
							}	
						},
						error: function (xhr, status, error) {
							console.log( xhr.responseText );
						}
					});
				}
	        } else {
	            // formMessage.append('<p>Došlo je do pogreške</p>');
	        }
	    }
	);
	
});

$(document).on('click', '.fb-share-btn', function(e) {
	e.preventDefault();

	var photo = $(this).closest('.mfp-figure').find('.mfp-img').attr('src');
	var imagePath = photo.slice(0, -4);
	imagePath = imagePath.substring(8);
	var homeUrl = window.location.protocol + "//" + window.location.host;
	console.log(photo);
	console.log(imagePath);

    FB.ui(
	    {
	        method: 'share',
	        mobile_iframe: true,
	        // name: 'Facebook Dialogs',
	        href: homeUrl + '/view/photos/' + imagePath,
	        link: homeUrl + '/view/photos/' + imagePath,
	        picture: homeUrl + photo,
	        // caption: 'Ishelf Book',
	        // description: 'your description'
	    },
	    function (response) {
	    	console.log( response );
	    }
	);
	
});

